












import { DataTableColDef, DataTableDef, DataTableRowDef } from '@/app_code/DataTable'
import { DropdownField, DropdownListItem, FormField } from '@/app_code/Forms'
import { Modal } from '@/app_code/Modals/Modal'
import DataTable from '@/components/UI/Elements/DataTable/DataTable.vue'
import DropdownList from '@/components/UI/Elements/Forms/DropdownList.vue'
import { GetContactModel } from 'truemarket-modules/src/models/api/crm'
import GetGroupedContactModel from 'truemarket-modules/src/models/api/crm/GetGroupedContactModel'
import { API, Services } from 'truemarket-modules/src/services'
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {
    DataTable
  }
})
export default class ContactsIndex extends Vue {
  private contacts: GetGroupedContactModel[] = []

  private dtDef = new DataTableDef(new DataTableRowDef(row => row.ContactId, [
    new DataTableColDef('Email', row => row.Email),
    new DataTableColDef('Name', row => `${row.Contacts[0].FirstName} ${row.Contacts[0].LastName}`),
    new DataTableColDef('Phone', row => row.Contacts[0].Phone)
  ]))

  loadData () {
    console.log(Services.API.Admin)
    Services.API.Admin.CRM.GetGroupedContacts().then((contacts) => {
      this.contacts = contacts
    })
  }

  mounted () {
    this.loadData()
  }

  editContact (contact: GetContactModel) {
    const mdl = new Modal({
      Heading: 'Edit Contact',
      FormFields: [
        new FormField({
          Type: 'email',
          Key: 'Email',
          Label: 'Email',
          Value: contact.Email
        }),
        new FormField({
          Type: 'text',
          Key: 'FirstName',
          Label: 'First Name',
          Value: contact.FirstName
        }),
        new FormField({
          Type: 'text',
          Key: 'LastName',
          Label: 'Last Name',
          Value: contact.LastName
        }),
        new FormField({
          Type: 'tel',
          Key: 'Phone',
          Label: 'Phone',
          Value: contact.Phone
        })
      ],
      Buttons: [
        {
          Text: 'Cancel',
          OnClick: () => {
            mdl.Hide()
          }
        },
        {
          Text: 'Save',
          OnClick: () => {
            mdl.GetFormValues().then((ds) => {
              const model = ds.BuildModel<GetContactModel>()

              model.ContactId = contact.ContactId

              Services.API.Admin.CRM.SaveContact(model).then(() => {
                mdl.Hide()

                this.loadData()
              })
            })
          }
        }
      ]
    })

    mdl.Show()
  }

  editItem (contact: GetGroupedContactModel) {
    if (contact.Contacts.length === 1) {
      this.editContact(contact.Contacts[0])
    } else {
      const mdl = new Modal({
        Heading: 'Select Version',
        FormFields: [
          new DropdownField({
            Key: 'ContactId',
            Label: 'Contact',
            Items: contact.Contacts.map((c) => {
              return new DropdownListItem(c.ContactId, `${c.FirstName} ${c.LastName} [${c.Phone}]`)
            })
          })
        ],
        Buttons: [
          {
            Text: 'Cancel',
            OnClick: () => {
              mdl.Hide()
            }
          },
          {
            Text: 'Edit',
            OnClick: () => {
              mdl.GetFormValues().then((vals) => {
                if (vals.Fields.length === 0) return

                const cid = vals.Fields[0].Value

                const ctc = contact.Contacts.find((itm) => itm.ContactId === cid)

                if (!ctc) return

                mdl.Hide()

                this.editContact(ctc)
              })
            }
          }
        ]
      })

      mdl.Show()
    }
  }
}
